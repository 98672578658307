import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { Modal, Table, Button, Icon, Typography } from 'antd'
import { bindActionCreators } from 'redux'
import {
  applyModification
} from '../../store/actions/workRequests'
import {
  renderDifferencesBetweenVersions
} from '../../utils/modificationUtils/index'
import './ModificationConfirmModal.css'
import { formatDate } from '../../utils/misc'

const { Paragraph } = Typography

class ModificationConfirmModal extends React.Component {

  apply = (record) => {
    this.props.applyModification(record.id, true)
  }

  notApply = (record) => {
    this.props.applyModification(record.id, false)
  }

  getColumns = () => {
    const isSuperAdmin = this.props.user.userType === 'superadmin'
    let columns = [{
      title: 'Modificación',
      dataIndex: 'change',
      render: (text, record) => {
        if (record.is_deletion) {
          return (
            <>
              <Icon type="delete"/> {`Eliminar Solicitud ${record.work_request.correlative}`} <br/>
              <Paragraph ellipsis={{ rows: 2, expandable: true }}>
                {`Razón: ${record.reason}`}
              </Paragraph>
            </>
          )
        } else {
          const {
            central, 
            unit, 
            line, 
            section, 
            substation, 
            sub_barra,
            sub_pano,
            sub_transformadores2D,
            sub_transformadores3D,
            sub_bancoscondensadores,
            sub_sistcomper,
            sub_compensadoresactivos,
            sub_condensadoresserie,
            sub_condensadoressincronos,
            sub_reactores,
            sub_medfacturacion
          } = this.props
          const entities = {
            central,
            unit,
            line,
            section,
            substation,
            sub_barra,
            sub_pano,
            sub_transformadores2D,
            sub_transformadores3D,
            sub_bancoscondensadores,
            sub_sistcomper,
            sub_compensadoresactivos,
            sub_condensadoresserie,
            sub_condensadoressincronos,
            sub_reactores,
            sub_medfacturacion
          }
          return renderDifferencesBetweenVersions(record, entities)
        }
      } 
    },
    {
      title: 'Solicitante',
      dataIndex: 'user',
      render: (text, record) => {
        const user = record.user
        return `${user.name} (${user.email})`
      } 
    },
    {
      title: 'Fecha de Solicitud',
      dataIndex: 'date',
      render: (text, record) => {
        return formatDate(record.created_at)
      }
    }]
    columns.push({
      title: 'Acción',
      dataIndex: '',
      render: (text, record) => {
        return (
          <>
            { isSuperAdmin ?
              <Button
                shape=''
                icon='check'
                type='primary'
                className='modification-button'
                onClick={
                  () => {
                    console.log('record----> ', record)
                    this.apply(record)
                  }
                }> 
                Aplicar
              </Button> : null
            }
            <Button
              shape=''
              icon='close'
              type='secondary'
              className='modification-button'
              onClick={
                () => {
                  this.notApply(record)
                }
              }> 
              {isSuperAdmin ? 'Rechazar' : 'Eliminar Solicitud'}
            </Button>
          </>
        )
      }
    })
    return columns
  }
 
  render() {
    const { visible, handleCancel, modifications, work_request } = this.props
    if( _.isEmpty(modifications)){
      return ''
    }

    return (
      <Modal
        title={`Modificaciones Pendientes para la Solicitud ${work_request.correlative}`} 
        visible={visible}
        destroyOnClose={true}
        onCancel={handleCancel}
        width={'80%'}
        className='modifications-modal'
        maskClosable={false}
        footer={<Button type="primary" onClick={handleCancel}>Cerrar</Button>}
      >
        <Table columns={this.getColumns()} dataSource={modifications} />
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  central: state.entities.central,
  unit: state.entities.unit,
  line: state.entities.line,
  section: state.entities.section,
  substation: state.entities.substation,
  sub_barra: state.entities.sub_barra,
  sub_pano: state.entities.sub_pano,
  sub_transformadores2D: state.entities.sub_transformadores2D,
  sub_transformadores3D: state.entities.sub_transformadores3D,
  sub_bancoscondensadores: state.entities.sub_bancoscondensadores,
  sub_sistcomper: state.entities.sub_sistcomper,
  sub_compensadoresactivos: state.entities.sub_compensadoresactivos,
  sub_condensadoresserie: state.entities.sub_condensadoresserie,
  sub_condensadoressincronos: state.entities.sub_condensadoressincronos,
  sub_reactores: state.entities.sub_reactores,
  sub_medfacturacion: state.entities.sub_medfacturacion
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  applyModification
}, dispatch)


 
export default connect(mapStateToProps, mapDispatchToProps)(ModificationConfirmModal)
 