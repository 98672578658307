/* $global localStorage */

import client from '../store/api/apiClient'
import { tokenConfig } from '../store/api'
import i18n from '../utils/i18n'
import { notification } from 'antd'
import moment from 'moment'

export function parseJSON (response) {
  return response.data
}

export function capitalize (word) {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export function createReducer (initialState, reducerMap) {
  return (state = initialState, action) => {
    const reducer = reducerMap[action.type]
    return reducer
      ? reducer(state, action.payload)
      : state
  }
}

export function debounce (func, wait, immediate) {
  let timeout

  return function () {
    const context = this
    const args = arguments
    const callNow = immediate && !timeout

    clearTimeout(timeout)

    timeout = setTimeout(function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }, wait)
    if (callNow) func.apply(context, args)
  }
}

export function getByIdMap (list, idField = 'id') {
  const result = {}
  list.forEach((item) => {
    result[item[idField]] = item
  })
  return result
}

export function formatProfile (profileObj) {
  return {
    id: profileObj.id,
    companyId: profileObj.company_id,
    companyName: profileObj.company_name,
    companyRut: profileObj.company_rut,
    infotecnicaId: profileObj.id_infotecnica,
    representedCompanies: profileObj.represented_companies,
    idReuc: profileObj.id_reuc,
    role: profileObj.role
  }
}

export function formatWRCalendarEvent (wrObj) {
  const startDate = wrObj.real_start_date ? new Date(wrObj.real_start_date) : new Date(wrObj.programmed_start_date)
  const endDate = wrObj.real_end_date ? new Date(wrObj.real_end_date) : new Date(wrObj.programmed_end_date)
  return {
    id: wrObj.id,
    title: wrObj.correlative || wrObj.current_status.name,
    start: startDate,
    end: endDate,
    desc: `${wrObj.work_description}`
  }
}

export function formatWRGanttEntry (wrObj, entities) {
  const startDate = wrObj.real_start_date ? new Date(wrObj.real_start_date) : new Date(wrObj.programmed_start_date)
  const endDate = wrObj.real_end_date ? new Date(wrObj.real_end_date) : new Date(wrObj.programmed_end_date)
  
  const isCentral = wrObj.installation_type 
  console.log('isCentral: ',isCentral)
  let mainInst = ''
  let subInst = ''

  if (isCentral === 'central') {
    console.log('CENTRAL ***********************************************************************')
    mainInst = entities.central.byId[wrObj.installation].nombre
    subInst = wrObj.sub_installation.map(

      sinst => entities.unit.byId[sinst].nombre
    ).join(', ')
    
  } else if (isCentral === 'line') {
    console.log('LINEA ***********************************************************************')
    mainInst = entities.line.byId[wrObj.installation].nombre
    subInst = wrObj.sub_installation.map(
      sinst => entities.section.byId[sinst].nombre
    ).join(', ')
    
  } else {
    console.log('SUBESTACION ***********************************************************************')
    mainInst = entities.substation.byId[wrObj.installation].nombre
    subInst = wrObj.sub_installation.map(
      //if el tipo
       sinst => entities.substation.byId[sinst].nombre 
    ).join(', ')   
  }

  return {
    id: wrObj.id,
    name: `Correlativo: ${wrObj.correlative}. ${isCentral === 'central' ? 'Central' : isCentral === 'line' ? 'Línea' : 'Subestación'}: ${mainInst}. ${isCentral === 'central' ? 'Unidades' : isCentral === 'line' ? 'Secciones' : 'Barras'}: ${subInst}`,
    start: startDate,
    end: endDate
  }

}

export function goToCognitoLogin () {
  const {
    REACT_APP_COGNITO_AUTH_HOST: cognitoAuthHost,
    REACT_APP_COGNITO_CLIENT_ID: cognitoClientId
  } = process.env
  const domain = window.location.origin
  //const cognitoLoginLocation = `${cognitoAuthHost}/login?response_type=code&client_id=${cognitoClientId}&redirect_uri=${domain}/callback/login`
  const cognitoLoginLocation = `${cognitoAuthHost}/login?response_type=code&client_id=${cognitoClientId}&redirect_uri=${domain}/callback/login`
  
  window.location.replace(cognitoLoginLocation)
}


export function filterSubInstallations (selected, installation, choices, companyId = undefined, companies = undefined, user) {
  console.log("sub-instalaciones")
  let currentPropietarioId = 0
  let representedCompaniesId = []
  let name = ''
  let rut = ''
  
  if (!companyId) {
    const { profiles, currentProfile } = user
    console.log({profiles});
    console.log({currentProfile});

    // se obtiene el perfil actual del usuario (currentProfile) y se busca en los perfiles (profiles) 
    // del usuario para encontrar el objeto de perfil actual (currentProfileObj)
    const currentProfileObj = profiles.find(profile => profile.id === currentProfile)
    console.log({currentProfileObj})

    // si no se encuentra el objeto de perfil actual se devuelve el arreglo AllIds vacío
    if (!currentProfileObj) {
      return { ...installation, allIds: [] }
    }
    currentPropietarioId = currentProfileObj.infotecnicaId
    console.log({currentPropietarioId})

    representedCompaniesId = currentProfileObj.representedCompanies
    console.log({representedCompaniesId})
  } else {
    // si companies está definido obtenemos datos específicos 
    if (companies) {
      currentPropietarioId = companies.byId[companyId].id_infotecnica
      console.log({currentPropietarioId})

      name = companies.byId[companyId].name
      console.log({name})

      rut = companies.byId[companyId].rut
      console.log({rut})

      representedCompaniesId = companies.byId[companyId].represented_companies
      console.log({representedCompaniesId})

    }
  }

  // se almacenan instalaciones filtradas
  const filteredInstallations = {
    byId: {}, allIds: [] 
  }

  if (installation === null) {
    return filteredInstallations
  }

  // si installation no es nulo se convierte en entero
  const installationId = parseInt(installation)
  console.log({installationId})

  // se itera sobre todas las identificaciones de elementos en choices.allIds
  const validChoices = choices.allIds.filter(elementId => {
    const choice = choices.byId[elementId];
    return (
      choice[`id_${selected.connectionId}`] === installationId &&
      (
        choice.id_propietario === currentPropietarioId ||
        choice.propietario_id === currentPropietarioId ||
        representedCompaniesId.includes(choice.id_propietario) ||
        representedCompaniesId.includes(choice.propietario_id)
      )
    );
  });
  console.log({validChoices})

  // se itera sobre las opciones válidas y se agregan al objeto filteredInstallations
  validChoices.forEach(installationId => {
    filteredInstallations.allIds.push(installationId)
    filteredInstallations.byId[installationId] = { ...choices.byId[installationId] }
  })

  return filteredInstallations
}

export function filterProfileInstallations (installations, user, companyId = undefined, companies = undefined, selectedAllSubstation) {
  // Return installations for the current user profile.

  let currentPropietarioId = 0
  let representedCompaniesId = []

  let name = ''
  let rut = ''

  // se verifica si companyId está definido 
  if (!companyId) {
    const { profiles, currentProfile } = user
    console.log({profiles});
    console.log({currentProfile});

    // se obtiene el perfil actual del usuario (currentProfile) y se busca en los perfiles (profiles) 
    // del usuario para encontrar el objeto de perfil actual (currentProfileObj)
    const currentProfileObj = profiles.find(profile => profile.id === currentProfile)
    console.log({currentProfileObj})

    // si no se encuentra el objeto de perfil actual se devuelve el arreglo AllIds vacío
    if (!currentProfileObj) {
      return { ...installations, allIds: [] }
    }
    currentPropietarioId = currentProfileObj.infotecnicaId
    console.log({currentPropietarioId})

    representedCompaniesId = currentProfileObj.representedCompanies
    console.log({representedCompaniesId})
  } else {
    // si companies está definido obtenemos datos específicos 
    if (companies) {
      currentPropietarioId = companies.byId[companyId].id_infotecnica
      console.log({currentPropietarioId})

      name = companies.byId[companyId].name
      console.log({name})

      rut = companies.byId[companyId].rut
      console.log({rut})

      representedCompaniesId = companies.byId[companyId].represented_companies
      console.log({representedCompaniesId})

    }
  }

  // se filtran las instalaciones basadas en el id_propietario actual
  const resultAllIds = installations.allIds.filter(id => {
    const entity = installations.byId[id]
    return (
      entity.id_propietario === currentPropietarioId || entity.propietario_id === currentPropietarioId ||
      representedCompaniesId.includes(entity.id_propietario) || representedCompaniesId.includes(entity.propietario_id)
    )
  })
  console.log({resultAllIds})
  console.log({companies});
  console.log({installations});

  // Si selectedAllSubstation es verdadero, se devuelve un objeto installations con todas las IDs sin filtrar.
  if(selectedAllSubstation){
    console.log({ ...installations, allIds: installations.allIds });
    return { ...installations, allIds: installations.allIds }
  }
  // Si no, se devuelve un objeto installations con las IDs filtradas según resultAllIds
  console.log({ ...installations, allIds: resultAllIds });
  return { ...installations, allIds: resultAllIds }
}

export const handleDownload = async (uid, type) => {
  const token = localStorage.getItem('token')
  const params = {
    name: uid,
    type,
    method: 'GET'
  }
  const res = await client().post(
    '/download-file/signed-url/',
    params,
    tokenConfig(token)
  )

  if (res.data && res.data.url) {
    window.open(res.data.url, 'Download')
    return
  }

  notification['error']({
    message: i18n.t('error.uploadFile.createSignedUrl.title'),
    description: i18n.t('error.uploadFile.createSignedUrl.description'),
  })
}

export function selectSearch (input, option) {
  return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

export function formatDate (date) {
  const timeFormat = 'DD MMMM YYYY HH:mm'
  return moment(date).local().format(timeFormat)
}
