
import {
  workRequestsConstants
} from '../constants/workRequests'
import {
  reqRequestDeletion,
  reqGetWorkRequests,
  reqGetWorkRequestDetails,
  confirmWorks,
  createNewRequest,
  reqChangeStatus,
  fetchWorkRequest,
  reqApplyModification,
  editOldWorkRequest,
  createMassiveNewRequest
} from '../api/workRequests'
import {
  closeAddRequestModal,
  closeDeleteRequestModal,
  closeAprovalRequestModal,
  closeMassiveRequestModal
} from './pmpm'
import { parseJSON } from '../../utils/misc'
import { statusChangeSuccess, statusChangeFailure } from '../../utils/workRequestUtils'
import { showNotification } from '../../utils/notification'

export function newWorkRequest (params) {
  console.log('parametros -> ',params)
  return (dispatch, getState) => {
    const { auth } = getState()
    dispatch({
      type: workRequestsConstants.ADD_WORK_REQUEST
    })
    createNewRequest(auth.token, params)
      .then(parseJSON)
      .then(data => {
        dispatch({
          type: workRequestsConstants.ADD_WORK_REQUEST_SUCCESS,
          payload: data
        })
        dispatch(closeAddRequestModal())
        return showNotification(
          'Ingreso Exitoso',
          'La solicitud fue ingresada correctamente',
          'success'
        )
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: workRequestsConstants.ADD_WORK_REQUEST_ERROR
        })
        return showNotification(
          'Ha Ocurrido un Error',
          'La solicitud no pudo ser ingresada',
          'error'
        )
      })
  }
}

export function newWorkRequestMassive (requests, programId) {
  return (dispatch, getState) => {
    const { auth } = getState()
    dispatch({
      type: workRequestsConstants.ADD_WORK_REQUEST_MASSIVE
    })
    createMassiveNewRequest(auth.token, requests, programId)
      .then(parseJSON)
      .then(data => {
        dispatch({
          type: workRequestsConstants.ADD_WORK_REQUEST_MASSIVE_SUCCESS,
          payload: data
        })
        dispatch(closeMassiveRequestModal())
        return showNotification(
          'Ingreso Exitoso',
          'Las solicitudes fueron ingresadas correctamente',
          'success'
        )
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: workRequestsConstants.ADD_WORK_REQUEST_MASSIVE_ERROR
        })
        return showNotification(
          'Ha Ocurrido un Error',
          'La solicitud no pudo ser ingresada',
          'error'
        )
      })
  }
}

export function editWorkRequest (isCoordinador, workRequest, params) {
  return (dispatch, getState) => {
    const { auth, entities: { programs } } = getState()
    const program = programs[programs.selectedProgram].id
    dispatch({
      type: workRequestsConstants.EDIT_WORK_REQUEST
    })
    editOldWorkRequest(auth.token, isCoordinador, workRequest, params, program)
      .then(parseJSON)
      .then(data => {
        dispatch({
          type: workRequestsConstants.EDIT_WORK_REQUEST_SUCCESS,
          payload: data.work_request
        })
        return data
      })
      .then(data => {
        dispatch({
          type: workRequestsConstants.ADD_MODIFICATION_REQUEST,
          payload: data.request_modification
        })
        dispatch(closeAddRequestModal())
        return showNotification(
          isCoordinador ? 'Edición Exitosa' : 'Solicitud Ingresada',
          isCoordinador ? 'Se han guardado los cambios correctamente' : 'Se han enviado las modificaciones al Coordinador.',
          'success'
        )
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: workRequestsConstants.EDIT_WORK_REQUEST_ERROR
        })
        return showNotification(
          'Ha Ocurrido un Error',
          'La solicitud no pudo ser ingresada',
          'error'
        )
      })
  }
}

export function getWorkRequests (programId, extras = {}) {
  return (dispatch, getState) => {
    const { auth } = getState()
    dispatch({
      type: workRequestsConstants.FETCH_WORK_REQUESTS
    })
    reqGetWorkRequests(auth.token, programId, extras)
      .then(response => {
        const { programs } = getState().entities
        // Prevent loading requests in the wrong ui program tab.
        if (programId === programs[programs.selectedProgram].id) {
          return parseJSON(response)
        } else return null
      })
      .then(data => {
        if (data !== null) {
          dispatch({
            type: workRequestsConstants.FETCH_WORK_REQUESTS_SUCCESS,
            payload: data.map(pmc => (pmc.work_request))
          })
        }
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: workRequestsConstants.FETCH_WORK_REQUESTS_ERROR
        })
      })
  }
}

export function getWorkRequestDetails (workRequestId) {
  return (dispatch, getState) => {
    const { auth } = getState()
    dispatch({
      type: workRequestsConstants.ADD_WORK_REQUEST
    })
    reqGetWorkRequestDetails(auth.token, workRequestId)
      .then(parseJSON)
      .then(data => {
        dispatch({
          type: workRequestsConstants.ADD_WORK_REQUEST_SUCCESS,
          payload: data
        })
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: workRequestsConstants.ADD_WORK_REQUEST_ERROR
        })
      })
  }
}

export function changeRequestStatus (workRequestIdList, params) {
  return (dispatch, getState) => {
    const { auth } = getState()
    dispatch({
      type: workRequestsConstants.CHANGE_WORK_REQUEST_STATUS
    })
    reqChangeStatus(auth.token, workRequestIdList, params)
      .then(parseJSON)
      .then(data => {
        dispatch({
          type: workRequestsConstants.CHANGE_WORK_REQUEST_STATUS_SUCCESS,
          payload: data
        })
      })
      .then(() => {
        dispatch(closeAprovalRequestModal())
        return statusChangeSuccess()
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: workRequestsConstants.CHANGE_WORK_REQUEST_STATUS_ERROR
        })
        return statusChangeFailure()
      })
  }
}

export function reloadWorkRequest (workRequestId) {
  return (dispatch, getState) => {
    const { auth } = getState()
    dispatch({
      type: workRequestsConstants.RELOAD_WORK_REQUEST
    })
    fetchWorkRequest(auth.token, workRequestId)
      .then(parseJSON)
      .then(data => {
        const workRequest = data.shift().work_request
        dispatch({
          type: workRequestsConstants.RELOAD_WORK_REQUEST_SUCCESS,
          payload: workRequest
        })
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: workRequestsConstants.RELOAD_WORK_REQUEST_ERROR
        })
      })
  }
}

export function addDeletionRequest (workRequestId, isCoordinador, user, params) {
  return (dispatch, getState) => {
    const { auth } = getState()
    dispatch({
      type: workRequestsConstants.ADD_DELETION_REQUEST
    })
    reqRequestDeletion(auth.token, workRequestId, isCoordinador, user, params)
      .then(parseJSON)
      .then(data => {
        dispatch({
          type: workRequestsConstants.ADD_MODIFICATION_REQUEST,
          payload: data.request_modification
        })
        return data
      })
      .then(data => {
        if (data.work_request) {
          dispatch({
            type: workRequestsConstants.EDIT_WORK_REQUEST_SUCCESS,
            payload: data.work_request
          })
        }
      })
      .then(() => {
        dispatch(closeDeleteRequestModal())
        return showNotification(
          isCoordinador ? 'Solicitud Eliminada' : 'Solicitud Ingresada',
          isCoordinador ? 'La Solicitud ha sido eliminada correctamente' : 'La solicitud debe ser aprobada por el Coordinador',
          'success'
        )
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: workRequestsConstants.ADD_DELETION_REQUEST_ERROR
        })
        return showNotification(
          'Ha ocurrido un error',
          'No pudo ingresarse la solicitud de eliminación',
          'error'
        )
      })
  }
}

export function reqConfirmWorks (workRequestId, params) {
  console.log('CONF: workRequestId -> ',workRequestId)
  console.log('CONF: params -> ',params)
  return (dispatch, getState) => {
    const { auth } = getState()
    dispatch({
      type: workRequestsConstants.CONFIRM_REQUEST_WORKS,
      trusty : true
    })
    console.log('auth.token ---->',auth.token)
    confirmWorks(auth.token, workRequestId, params)
      .then(parseJSON)
      .then(data => {
        console.log('CONF: parseJSON -> ',parseJSON)
        console.log('CONF: data -> ',data)
        dispatch({
          type: workRequestsConstants.CONFIRM_REQUEST_WORKS_SUCCESS,
          payload: data,
          trusty : true
        })
        return showNotification(
          'Operación Completada',
          'Trabajo confirmado correctamente',
          'success'
        )
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: workRequestsConstants.CONFIRM_REQUEST_WORKS_ERROR
        })
        return showNotification(
          'Falló Creación de Solicitud en Neomante',
          'No fue posible generar la Solicitud de Desconexión/Intervención. Reintente.',
          'error'
        )
      })
  }
}

export function applyModification (modificationId, shouldApply) {
  return (dispatch, getState) => {
    const { auth } = getState()
    dispatch({
      type: workRequestsConstants.APPLY_REQUEST_MODIFICATION
    })
    reqApplyModification(auth.token, modificationId, shouldApply)
      .then(parseJSON)
      .then(data => {
        console.log('auth.token: ',auth.token)
        console.log('modificationId: ',modificationId)
        console.log('shouldApply: ',shouldApply)
        dispatch({
          type: workRequestsConstants.APPLY_REQUEST_MODIFICATION_SUCCESS,
          payload: data.request_modification
        })
        dispatch({
          type: workRequestsConstants.EDIT_WORK_REQUEST_SUCCESS,
          payload: data.work_request
        })
        return showNotification(
          'Operación Completada',
          'Modificación Aplicada Correctamente',
          'success'
        )
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: workRequestsConstants.APPLY_REQUEST_MODIFICATION_ERROR
        })
        return showNotification(
          'Ha ocurrido un error',
          'No fue posible aplicar la modificación',
          'error'
        )
      })
  }
}

export function changeWorkRequestSelection (workRequestId) {
  return {
    type: workRequestsConstants.CHANGE_WORK_REQUEST_SELECTION,
    payload: workRequestId
  }
}

export function clearWorkRequestSelection () {
  return {
    type: workRequestsConstants.CLEAR_WORK_REQUEST_SELECTION
  }
}
