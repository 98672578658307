import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Row, Table, Icon, Button, Modal, Form} from 'antd'
import {
  fetchCompanies,
  changeCompany,
  fetchInfotecnicaCompanies
} from '../../store/actions/entities'
import FormItem from '../../components/FormItem'
import SelectCompany from '../../components/Admin/SelectCompany'


const { Column } = Table

class CompanyAdmin extends React.Component {

  constructor(props){
    super(props)
    this.initialState = {
      isModalOpen: false,
      currentCompany: {},
      showHelp: false
    }
    this.state = {
      ...this.initialState
    }
  }

  componentDidMount () {
    this.props.fetchCompanies()
    this.props.fetchInfotecnicaCompanies()
  }

  openModal = (company) => {
    this.setState({
      currentCompany: company,
      isModalOpen: true
    })
  }

  closeModal = () => {
    this.setState({
      ...this.initialState
    })
    this.props.form.resetFields()
  }

  submitForm = company => {
    const { form, changeCompany } = this.props
    form.validateFields((err, values) => {
      if(!err){
        changeCompany(company, values)
        this.closeModal()
      }
    })
  }

  render () {
    const { currentCompany } = this.state
    const {
      companies: { fetchingCompanies },
      companies,
      infotecnicaCompanies,
      form
    } = this.props
    const { getFieldDecorator } = form
    const companyList = companies.allIds.map(id => ({...companies.byId[id], key: id})).sort((a,b) => a.name.localeCompare(b.name))
    return (
      <React.Fragment>
        <Row>
          <h3>
            <span>Administrador de Empresas</span>
            <Button
              style={{marginLeft: '6px'}}
              className={"help-button"}
              type="secondary"
              onClick={()=> this.setState({showHelp: true})}>
              <Icon type="question-circle"/>
            </Button>
          </h3>
        </Row>
        <Row>
          <Table
            loading={fetchingCompanies}
            dataSource={companyList}>
            <Column title="Nombre" dataIndex="name" key="name" />
            <Column title="Rut" dataIndex="rut" key="rut" />
            <Column title="ID Infotécnica" dataIndex="id_infotecnica" key="id_infotecnica" />
            <Column title="ID REUC" dataIndex="id_reuc" key="id_reuc" />
            <Column title='Acciones' key="actions" render={(text, record, index) => {
              return <Button onClick={() => this.openModal(record)}>
                <Icon type="edit"/>
                Editar ID
              </Button>
            }}/>
          </Table>
          <Modal
            title="Editar Empresa"
            visible={this.state.isModalOpen}
            onOk={() => this.submitForm(currentCompany)}
            onCancel={this.closeModal}
          >
            <Form>
              <p>Nombre: {currentCompany.name}</p>
              <p>Rut: {currentCompany.rut}</p>
              <p style={{marginBottom: '5px'}}>ID REUC: {currentCompany.id_reuc}</p>
              <FormItem 
                label="ID Infotécnica"
                formId="id_infotecnica"
                decorator={getFieldDecorator}
                args={{
                  initialValue: currentCompany.id_infotecnica,
                  rules: [
                    {required: true, message: 'Indique el ID de la empresa'}
                  ]
                }}
              >
                <SelectCompany
                  companies={infotecnicaCompanies}
                />
              </FormItem>
              <FormItem 
                label="Empresas representadas"
                formId="represented_companies"
                decorator={getFieldDecorator}
                args={{
                  initialValue: currentCompany.represented_companies
                }}
              >
                <SelectCompany
                  mode="multiple"
                  companies={infotecnicaCompanies}
                />
              </FormItem>
            </Form>
          </Modal>
          <Modal
            title="Administrador de Empresas"
            visible={this.state.showHelp}
            okButtonProps={{
              style: {display: 'none'}
            }}
            cancelButtonProps={{
              type: "primary"
            }}
            onCancel={()=> this.setState({showHelp: false})}
            cancelText="Ok"
          >
            <p>
              En esta sección puedes: 
            </p>
            <ul>
              <li>
                Agregar o editar el ID de Infotécnica de las empresas que
                aparezcan en la lista. Esto permitirá ligar las empresas en esta aplicación a las
                empresas de Infotécnica.
              </li>
              <li>
                Agregar o editar las representaciones de una empresa.
              </li>
            </ul>
          </Modal>
        </Row>
      </React.Fragment>
    )
  }

}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    fetchCompanies,
    fetchInfotecnicaCompanies,
    changeCompany
  }, dispatch)

const mapStateToProps = (state) => ({
  companies: state.entities.companies,
  infotecnicaCompanies: state.entities.infotecnicaCompanies
})

const companyAdmin = Form.create({ name: 'companyAdmin' })(CompanyAdmin);
export default connect(mapStateToProps, mapDispatchToProps)(companyAdmin)
