import client from '../api/apiClient'
import { tokenConfig } from './'

export function reqGetWorkRequests (token, programId, extras = {}) {
  let params = ''
  for (const key of Object.keys(extras)) {
    params = params + `&work_request__${key}=${extras[key]}`
  }
  params = params + `&programmed_maintenance__id=${programId}`
  return client().get(`/programmed_maintenance_change/?${params}`, tokenConfig(token))
}

// Vamos a hacer una consulta aparte? o dejamos todo pre-cargado en el front?
export function reqGetWorkRequestDetails (token, workRequestId) {
  return client().get(`/work_request/${workRequestId}`, tokenConfig(token))
}

export function createNewRequest (token, params) {
  return client().post('/work_request/new_work_request/', params, tokenConfig(token))
}

export function createMassiveNewRequest (token, requests, program) {
  
  return client().post('/work_request/new_work_request/', { many: true, requests: requests, program_id: program }, tokenConfig(token))
}

export function editOldWorkRequest (token, isCoordinador, workRequest, params, programId) {
  return client().post('/work_request/edit_work_request/', {
    request_id: workRequest.id,
    params: { ...params },
    is_coordinador: isCoordinador,
    program_id: programId
  }, tokenConfig(token))
}

export function reqChangeStatus (token, idList, params) {
  return client().post('/work_request/change_request_status/', { ...params, request_id_list: idList }, tokenConfig(token))
}

export function fetchWorkRequest (token, workRequestId) {
  return client().get(`/programmed_maintenance_change/?work_request__id=${workRequestId}`, tokenConfig(token))
}

export function reqRequestDeletion (token, workRequestId, isCoordinador, user, params) {
  return client().post('/work_request/add_deletion_request/', {
    ...params,
    is_coordinador: isCoordinador,
    request_id: workRequestId,
    user_id: user
  }, tokenConfig(token))
}

export function confirmWorks (token, workRequestId, params) {
  return client().post('/work_request/confirm_works/', {
    request_id: workRequestId, ...params
  }, tokenConfig(token))
}

export function reqDownloadReport (token, params) {
  return client().post('/download_report/', { params }, tokenConfig(token))
}

export function reqStatusDownloadReport (token, fileName) {
  return client().get('/status/download_report/', { params: { file_name: fileName }, ...tokenConfig(token) })
}

export function reqOpenDownloadReport (token, fileName) {
  return client().get(
    '/download_report_file/',
    {
      params: {
        file_name: fileName
      },
      responseType: 'blob',
      ...tokenConfig(token)
    }
  )
}

export function reqApplyModification (token, modificationId, shouldApply) {
  return client().post(
    '/request_modification/apply_modification/', {
      id: modificationId,
      apply: shouldApply
    }, tokenConfig(token))
}
